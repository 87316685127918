<template>
  <div class="rank-table" :class="{'amp-table': isAmp}">
    <div class="head flex-between" @click="openDialog">
      <div>
        <span class="name-ch color-fff">{{$translate[name].ch}}</span>
        <span class="name-en color-2edbff">{{$translate[name].en}}</span>
      </div>
    </div>
    <div class="table">
      <div class="th flex-center">
        <div>-</div>
        <div>GMV</div>
        <div>订单数</div>
        <div>毛利</div>
        <div>单笔毛利</div>
        <div>曝光</div>
        <div>访问率</div>
        <div>下单转化率</div>
      </div>
      <div class="data1 flex-center">
        <div>-</div>
        <div v-for="(item, idx) in body.data1" :key="idx">{{item}}</div>
      </div>
      <div class="data2 flex-center">
        <div>目标达成率</div>
        <div v-for="(item, idx) in body.data2" :key="idx">{{item}}</div>
      </div>
      <div class="data3 flex-center" v-for="(td, idx1) in body.data3" :key="idx1">
        <div class="date">
          <date-picker
            v-model="td.time"
            :type="upType"
            :format="upFormat"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :clearable="false"
            prefix-icon="none"
            @change="dateChange(index, idx1, td.time, td.platType)"
            :picker-options="pickerOptions">
          </date-picker>
        </div>
        <div v-for="(detail, idx2) in td.data" :key="idx2">
          <div>{{detail.value}}</div>
          <div class="progress">
            <div :class="detail.type === 1 ? 'minus' : 'plus'" :style="{'width': detail.progress + '%'}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DatePicker} from 'element-ui' 
export default {
  name: 'RightTable',
  components: {
    DatePicker
  },
  props: {
    index: { // 对应的当前下标
      default: 0,
      type: Number
    },
    name: { // 表格名称索引
      default: '',
      type: String,
      required: true
    },
    dateType: { // 日期类型（天、周、月）
      default: 1,
      type: Number
    },
    tableBody: { // 主体数据
      default: () => {},
      type: Object
    },
    isAmp: { // 是否放大
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  computed: {
    head() {
      return this.tableHead
    },
    body() {
      return this.tableBody
    },
    upType() {
      let i = {
        1: 'date',
        2: 'week',
        3: 'month'
      }
      return i[this.dateType * 1] || 'date'
    },
    upFormat() {
      let i = {
        1: 'yyyy-MM-dd',
        2: 'yyyy第W周',
        3: 'yyyy-MM'
      }
      return i[this.dateType * 1] || 'yyyy-MM-dd'
    }
  },
  watch: {
  },
  methods: {
    tableTdClick(e) {
      this.$emit('toNext', e)
    },
    dateChange(index1, index2, date, platType) {
      this.$emit('changeDate', index1, index2, date, platType)
    },
    openDialog() {
      this.$emit('openDialog', this.index, this.isAmp)
    }
  }
}
</script>

<style lang="less">
.rank-table {
  .mua-table__head .mua-th {
    font-size: 0.75rem !important;
  }
  .date {
    .el-input__inner {
      border: 0;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 100%;
    }
    .el-input--prefix .el-input__inner {
      padding: 0;
      margin-top: 3px;
    }
  }
}
.amp-table {
  .date {
    .el-input__inner {
      font-size: 1.5rem;
    }
    .el-input--prefix .el-input__inner {
      margin-top: 0.85rem;
    }
  }
}
</style>

<style scoped lang="less">
.rank-table {
  padding: 0.5rem 1rem;
  width: 100%;
  border: 2px solid #3B69AE;
  background-image: linear-gradient(180deg, rgba(18, 24, 61, 0.6) 0%, rgba(18, 22, 69, 0.6) 100%);
  box-shadow: inset 0 0 .75rem rgba(100, 200, 255, .5);
  box-sizing: border-box;
  .head {
    padding-bottom: 4px;
    cursor: pointer;
    span {
      display: block;
    }
    .name-ch {
      font-size: 0.875rem;
    }
    .name-en {
      font-size: 0.625rem;
    }
  }
  .flex-center {
    display: flex;
    justify-content: space-between;
    align-items:stretch;
  }
  .table {
    width: 100%;
    box-sizing: border-box;
    font-size: .75rem;
    text-align: center;
    border: 1px solid #2EDBFF;
    .th {
      background: linear-gradient( 270deg, rgba(1, 209, 138, 1) 0%, rgba(36, 131, 255, 1) 100%);
      border-bottom: 1px solid #2EDBFF;
      > div {
        width: 12.5%;
        color: #FFF;
        padding: .25rem 0;
      }
      > div:not(:last-child) {
        border-right: 1px solid #2EDBFF;
      }
    }
    .data1, .data2 {
      border-bottom: 1px solid #2EDBFF;
      > div {
        width: 12.5%;
        color: #FFF;
        padding: .5rem 0;
      }
      > div:not(:last-child) {
        border-right: 1px solid #2EDBFF;
      }
    }
    .data3:not(:last-child) {
      border-bottom: 1px solid #2EDBFF;
    }
    .data3 {
      > div {
        width: 12.5%;
        color: #FFF;
        padding: .125rem .25rem;
        position: relative;
      }
      > div:not(:last-child) {
        border-right: 1px solid #2EDBFF;
      }
      .progress {
        position: relative;
        width: 100%;
        height: 6px;
        margin: 2px 0;
        border-radius: 3px;
        overflow: hidden;
        background-color: #2F344B;
      }
      .minus, .plus {
        position: absolute;
        top: 0;
        height: 6px;
      }
      .minus {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: #01D18A;
        right: 70%;
      }
      .plus {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        background-color: #E6081F;
        left: 30%;
      }
    }
  }
}
.amp-table {
  padding: 1rem 1.5rem;
  .head {
    cursor: auto;
    padding-bottom: 12px;
    .name-ch {
      font-size: 1.625rem;
    }
    .name-en {
      font-size: 1.25rem;
    }
  }
  .table {
    font-size: 1.25rem;
    .th {
      > div {
        padding: .75rem 0;
      }
    }
    .data1, .data2 {
      > div {
        padding: 1rem 0;
      }
    }
    .data3 {
      > div {
        padding: 0.7rem 0.25rem;
      }
      .progress {
        height: 12px;
        margin: 4px 0;
        border-radius: 6px;
      }
      .minus, .plus {
        height: 12px;
      }
      .minus {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      .plus {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}
</style>
