/**
 * @param {*} data 接口返回的数据处理
 * @return {*} back返回值是一个对象，格式如下
 * back = {
 *  name1: [],
 *  name2: [],
 *  ...
 * }
 * name 父组件定义所需的数据 $translate的键值 三者互相对应
 */

import { strip, round, divide, times } from '@/utils/numberPrecision'
function targetProfitChart(data) {
  const back = {
    profitGrailFlow: [],
    profitMeituanFlow: [],
    profitEleFlow: [],
    oddGrailFlow: [],
    oddMeituanFlow: [],
    oddEleFlow: []
  }
  const source = JSON.parse(JSON.stringify(data))
  const sPart1 = [{ // 毛利目标进度
    key: 'profitGrailFlow',
    value: source.totalTarget
  }, {
    key: 'profitMeituanFlow',
    value: source.totalMtTarget
  }, {
    key: 'profitEleFlow',
    value: source.totalEleTarget
  }]
  const sPart2 = [{ // 单日目标进度
    key: 'oddGrailFlow',
    value: source.dayTarget
  }, {
    key: 'oddMeituanFlow',
    value: source.dayMtTarget
  }, {
    key: 'oddEleFlow',
    value: source.dayEleTarget
  }]
  const yaxis = []
  for (let i = 1, len = source.maxDays; i <= len; i++) {
    yaxis.push(i)
  }
  sPart1.forEach(item1 => {
    let atotal = []
    let [bdata, adata] = [item1.value, {
      lastMonth: [],
      currentMonth: [],
      grandTotal: [],
      targetReach: []
    }]
    for (let i = 1, len = source.maxDays; i <= len; i++) {
      atotal.push(round(divide(+bdata.total, 10000), 2, true))
    }
    for (const key in bdata.lastMonth) {
      adata.lastMonth.push(round(divide(+bdata.lastMonth[key], 10000), 2, true))
    }
    for (const key in bdata.currentMonth) {
      adata.currentMonth.push(round(divide(+bdata.currentMonth[key], 10000), 2, true))
    }
    for (const key in bdata.grandTotal) {
      adata.grandTotal.push(round(divide(+bdata.grandTotal[key], 10000), 2, true))
    }
    for (const key in bdata.targetReach) {
      adata.targetReach.push(round(+bdata.targetReach[key], 2, true))
    }
    back[item1.key] = [
      yaxis,
      {name: '上月数据(万)', data: adata.lastMonth},
      {name: '当月数据(万)', data: adata.currentMonth},
      {name: '累计目标(万)', noTooltip: 'no', data: adata.grandTotal},
      {name: '目标达成率(%)', data: adata.targetReach, isRight: true},
      {name: '总目标(万)', noTooltip: 'no', data: atotal}
    ]
  })
  sPart2.forEach(item1 => {
    let [bdata, atotal, adata] = [item1.value, [], {
      lastMonth: [],
      currentMonth: [],
      target: []
    }]
    for (let i = 1, len = source.maxDays; i <= len; i++) {
      atotal.push(bdata.total)
    }
    for (const key in bdata.lastMonth) {
      adata.lastMonth.push(round(+bdata.lastMonth[key], 2, true))
    }
    for (const key in bdata.currentMonth) {
      adata.currentMonth.push(round(+bdata.currentMonth[key], 2, true))
    }
    for (const key in bdata.target) {
      adata.target.push(round(+bdata.target[key], 2, true))
    }
    back[item1.key] = [
      yaxis,
      {name: '上月数据', data: adata.lastMonth},
      {name: '当月数据', data: adata.currentMonth},
      {name: '毛利目标', data: adata.target}
    ]
  })
  return back
}

function targetProfitTable(data) {
  const source = JSON.parse(JSON.stringify(data))
  const back = {
    profitGrail: {},
    profitMt: {},
    profitEle: {}
  }
  const setData = [{
    name: 'profitGrail',
    data: source.totalData
  }, {
    name: 'profitMt',
    data: source.mtData
  }, {
    name: 'profitEle',
    data: source.eleData
  }]
  setData.forEach(item1 => {
    let [data, rate] = [item1.data.data, item1.data.rate]
    back[item1.name].data1 = {
      GMV: round(divide(+data.GMV, 10000), 2, true) + '万',
      order_num: round(+data.order_num, 0, false),
      profit: round(divide(+data.profit, 10000), 2, true) + '万',
      average_profit: round(data.average_profit, 2, true),
      push_no: round(divide(+data.push_no, 10000), 2, true) + '万',
      visit_rate: round(times(+data.visit_rate, 100), 2, true) + '%',
      order_rate: round(times(+data.order_rate, 100), 2, true) + '%'
    }
    back[item1.name].data2 = {
      GMV: '',
      order_num: round(+rate.order_num, 2, false) + '%',
      profit: round(+rate.profit, 2, true) + '%',
      average_profit: '',
      push_no: round(+rate.push_no, 2, true) + '%',
      visit_rate: round(+rate.visit_rate, 2, true) + '%',
      order_rate: round(+rate.order_rate, 2, true) + '%'
    }
  })
  return back
}

function singleProfitTable(time, platType, root, data) {
  const back = {
    time,
    platType,
    data: []
  }
  const pendData = [
    round(times((root.GMV * 1 - data.GMV * 1) / (data.GMV * 1), 100), 0, false), // GMV
    round(times((root.order_num * 1 - data.order_num * 1) / (data.order_num * 1), 100), 0, false), // 订单数
    round(times((root.profit * 1 - data.profit * 1) / (data.profit * 1), 100), 0, false), // 毛利
    round(times((root.average_profit * 1 - data.average_profit * 1) / (data.average_profit * 1), 100), 0, false), // 单笔毛利
    round(times((root.push_no * 1 - data.push_no * 1) / (data.push_no * 1), 100), 0, false), // 曝光
    round(times((root.visit_rate * 1 - data.visit_rate * 1) / (data.visit_rate * 1), 100), 0, false), // 访问率
    round(times((root.order_rate * 1 - data.order_rate * 1) / (data.order_rate * 1), 100), 0, false) // 下单转化率
  ]
  pendData.forEach(item => {
    let number = isFinite(item) ? item : 0
    if (number < 0) {
      back.data.push({
        value: number + '%',
        type: 1,
        progress: Math.abs(strip(number * 0.3))
      })
    } else {
      back.data.push({
        value: number + '%',
        type: 2,
        progress: Math.abs(strip(number * 0.7))
      })
    }
  })
  return back
}

function rankPorfitTable(data) {
  const back = {
    profitYield: data.profit_rate,
    profitRank: data.profit_rank,
    profitAdRate: data.profit_adfee
  }
  back.profitYield.forEach(item => {
    item.total = round(+item.total, 2, true) + '%'
    item.ele = round(+item.ele, 2, true) + '%'
    item.mt = round(+item.mt, 2, true) + '%'
  })
  back.profitAdRate.forEach(item => {
    item.ad_fee = round(item.ad_fee, 2, true) + '%'
    item.ad_rate = round(item.ad_rate, 2, true) + '%'
    item.eleAd_orderPrice = round(item.eleAd_orderPrice, 2, true) + '%'
    item.mtAd_orderPrice = round(item.mtAd_orderPrice, 2, true) + '%'
  })
  return back
}

function rankOrderTable(data) {
  const back = {
    orderYield: data.profit_rate,
    orderRank: data.profit_rank
  }
  back.orderYield.forEach(item => {
    item.total = round(+item.total, 2, true) + '%'
    item.ele = round(+item.ele, 2, true) + '%'
    item.mt = round(+item.mt, 2, true) + '%'
  })
  return back
}

function rankPorfitChart(data) {
  const source = JSON.parse(JSON.stringify(data))
  const back = {
    profitRankChart: [],
    profitAdRateChart: []
  }
  let stop = false
  let yaxis = [] // 动态y轴

  for (let e1 in source.profit_rate) {
    let key = e1
    let value = []
    for (let e2 in source.profit_rate[e1]) {
      if (!stop) yaxis.push(e2.split('-')[2] * 1)
      value.push(
        round(+source.profit_rate[e1][e2], 2, true)
      )
    }
    if (!stop) stop = true
    back.profitRankChart.push({
      name: key + '(%)',
      data: value
    })
  }
  back.profitRankChart.unshift(yaxis)

  for (let e1 in source.ad_fee) {
    let key = e1
    let value = []
    for (let e2 in source.ad_fee[e1]) {
      if (!stop) yaxis.push(e2.split('-')[2] * 1)
      value.push(
        round(+source.ad_fee[e1][e2], 2, true)
      )
    }
    if (!stop) stop = true
    back.profitAdRateChart.push({
      name: key,
      data: value
    })
  }
  back.profitAdRateChart.unshift(yaxis)

  return back
}

function rankOrderChart(data) {
  const source = JSON.parse(JSON.stringify(data))
  const back = {
    orderRankChart: []
  }
  let stop = false
  let yaxis = [] // 动态y轴
  for (let e1 in back) {
    for (let e2 in source.profit_rate) {
      let key = e2
      let value = []
      for (let e3 in source.profit_rate[e2]) {
        if (!stop) yaxis.push(e3.split('-')[2] * 1)
        value.push(
          round(+source.profit_rate[e2][e3], 2, true)
        )
      }
      if (!stop) stop = true
      back[e1].push({
        name: key,
        data: value
      })
    }
    back[e1].unshift(yaxis)
  }
  return back
}

export {
  targetProfitChart,
  targetProfitTable,
  singleProfitTable,
  rankPorfitTable,
  rankOrderTable,
  rankPorfitChart,
  rankOrderChart
}