<template>
  <div class="rank-table" :class="{'rank-pm': showPM}">
    <div class="head flex-between">
      <div>
        <span class="color-fff font-14">{{$translate[name].ch}}</span>
        <span class="color-2edbff font-10">{{$translate[name].en}}</span>
      </div>
    </div>
    <mua-table class="table-view" :head="head" :data="body" background @click="changeData"></mua-table>
  </div>
</template>

<script>
export default {
  name: 'LevelZeroOne',
  props: {
    tableHead: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    tableBody: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    name: {
      default: '',
      type: String,
      required: true
    },
    showPM: { // 是否给前3显示奖牌
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
    }
  },
  computed: {
    head() {
      return this.tableHead
    },
    body() {
      let body = JSON.parse(JSON.stringify(this.tableBody))
      return body
    }
  },
  watch: {
  },
  methods: {
    changeData(e) {
      this.$emit('toNext', e)
    }
  }
}
</script>

<style lang="less">
.rank-table .mua-table__head .mua-th {
  font-size: 0.75rem !important;
}
.rank-pm .mua-table__body {
  .mua-tr {
    &:first-child, &:nth-child(2), &:nth-child(3) {
      position: relative;
    }
    &:first-child::before, &:nth-child(2)::before, &:nth-child(3)::before {
      content: '';
      position: absolute;
      top: 0;
      left: 2%;
      width: 15px;
      height: 20px;
      margin: 2px 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:first-child::before {
      background-image: url('../../../assets/pm1.png');
    }
    &:nth-child(2)::before {
      background-image: url('../../../assets/pm2.png');
    }
    &:nth-child(3)::before {
      background-image: url('../../../assets/pm3.png');
    }
  }
}
</style>

<style scoped lang="less">
.rank-table {
  padding: 0.5rem 1rem;
  width: 100%;
  border: 2px solid #3B69AE;
  background-image: linear-gradient(180deg, rgba(18, 24, 61, 0.6) 0%, rgba(18, 22, 69, 0.6) 100%);
  box-shadow: inset 0 0 .75rem rgba(100, 200, 255, .5);
  box-sizing: border-box;
  .head {
    padding-bottom: 4px;
    span {
      display: block;
    }
  }
  .sort {
    display: inline-block;
    height: 0.75rem;
    line-height: 0.75rem;
    padding: 0 2px;
    margin-left: 2px;
    border-radius: 50%;
    background-image: linear-gradient(270deg, #07866B 0%, #1C5AB3 100%);
    cursor: pointer;
    .sort-icon {
      width: 6px;
      height: 7px;
    }
  }
}
</style>
