<template>
  <div :class="['dataview', bgColor]">
    <div class="head flex-between">
      <div>
        <span class="color-fff font-14">{{$translate[name].ch}}</span>
        <span class="color-2edbff font-10">{{$translate[name].en}}</span>
      </div>
      <span class="font-28 color-2edbff" v-if="index">\{{index}}</span>
    </div>
    <mua-grail-line
      class="line-chart"
      :yaxis="yaxis"
      :data="flowData"
      :series="series"
      :optionYaxisUnit="optionYaxisUnit"
      :hasTwoYaxis="hasTwoYaxis"
    />
  </div>
</template>

<script>
export default {
  name: 'LevelTwoChart',
  props: {
    body: Array,
    name: {
      type: String,
      required: true
    },
    special: {
      type: Number
    },
    background: String
  },
  data() {
    return {
      color: ['#1890ff','#c23531', '#999', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570'], // 折线颜色
      yaxis: [], // Y轴配置
      value: [], // 图表数据
      optionYaxisUnit: '', // 自定义Y轴数据单位
      hasTwoYaxis: false
    }
  },
  watch: {
    body() {
      this.init()
    }
  },
  computed: {
    bgColor() {
      return this.background ? this.background : 'blue'
    },
    index() {
      return this.$translate[this.name].index
    },
    flowData() {
      let value = []
      this.value.forEach((e) => {
        if (e.show) value.push(e.data)
      })
      return value
    },
    series() {
      let series = []
      this.value.forEach((e) => {
        if (e.show) series.push(e.series)
      })
      return series
    }
  },
  methods: {
    init() {
      let data = this.body.slice(1)
      let value = []
      data.forEach((ele, idx) => {
        let index = idx.toString().slice(-1)
        let yIndex = ele.isRight ? 1 : 0 // 是否切换为右侧Y轴：百分数
        if (!this.hasTwoYaxis && yIndex === 1) this.hasTwoYaxis = true
        value.push({
          show: true,
          color: this.color[index],
          name: ele.name,
          data: ele.data,
          series: {
            name: ele.name,
            yAxisIndex: yIndex,
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            lineStyle: {color: this.color[index]},
            itemStyle: {color: this.color[index]},
            tooltip: {
              show: ele.noTooltip !== 'no'
            }
          }
        })
      })
      if (this.special === 1) {
        this.optionYaxisUnit = '万'
      }
      this.value = value
      this.yaxis = this.body[0] // .map(item => item + '日')
    }
  }
}
</script>

<style scoped lang="less">
.dataview {
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
  box-sizing: border-box;
  .head {
    padding-bottom: 4px;
    span {
      display: block;
    }
  }
  .line-chart {
    width: 100%;
    height: 280px;
  }
}
.blue {
  border: 2px solid #3B69AE;
  background-image: linear-gradient(180deg, rgba(18, 24, 61, 0.7) 0%, rgba(18, 22, 69, 0.7) 100%);
}
.green {
  border: 2px solid #3B69AE;
  background: linear-gradient(270deg, rgba(7, 128, 134, .3) 0%, rgba(28, 90, 179, .3) 100%);
}
</style>
