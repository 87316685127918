<template>
  <main class="main">

    <div class="flex-between font-22 color-fff">
      <div class="title">数据情况</div>
      <div class="flex-between">
        <el-select v-model="dataParams.value" placeholder="请选择" filterable multiple collapse-tags clearable v-if="dataParams.pattern === 2" @change="selectorHandle(2)">
          <el-option v-for="item in dataOptions.city" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <el-select v-model="dataParams.value" placeholder="请选择" filterable multiple collapse-tags clearable v-else-if="dataParams.pattern === 3" @change="selectorHandle(2)">
          <el-option v-for="item in dataOptions.group" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <el-select v-model="dataParams.value" placeholder="请选择" filterable multiple collapse-tags clearable v-else-if="dataParams.pattern === 4" @change="selectorHandle(2)">
          <el-option v-for="item in dataOptions.operate" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <el-select v-model="dataParams.value" placeholder="请选择" filterable multiple collapse-tags clearable v-else-if="dataParams.pattern === 5" @change="selectorHandle(2)">
          <el-option v-for="item in dataOptions.shop" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <date-picker
          class="ml-36"
          v-model="dataParams.date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          @change="selectorHandle(2)">
        </date-picker>
        <mua-button-group class="ml-36">
          <mua-button :type="dataParams.period === 1 ? 'active' : ''" @click="selectorHandle(0, 1)">天</mua-button>
          <mua-button :type="dataParams.period === 2 ? 'active' : ''" @click="selectorHandle(0, 2)">周</mua-button>
          <mua-button :type="dataParams.period === 3 ? 'active' : ''" @click="selectorHandle(0, 3)">月</mua-button>
        </mua-button-group>
        <mua-button-group class="ml-36">
          <mua-button :type="dataParams.pattern === 1 ? 'active' : ''" @click="selectorHandle(1, 1)">大盘</mua-button>
          <mua-button :type="dataParams.pattern === 2 ? 'active' : ''" @click="selectorHandle(1, 2)">城市</mua-button>
          <mua-button :type="dataParams.pattern === 3 ? 'active' : ''" @click="selectorHandle(1, 3)">总组</mua-button>
          <mua-button :type="dataParams.pattern === 4 ? 'active' : ''" @click="selectorHandle(1, 4)">运营</mua-button>
          <mua-button :type="dataParams.pattern === 5 ? 'active' : ''" @click="selectorHandle(1, 5)">门店</mua-button>
        </mua-button-group>
      </div>
    </div>
    <div class="profit-data">
      <div class="left">
        <left-chart
          class="line-chart"
          v-for="item in profitData"
          :name="item.name"
          :key="item.name"
          :body="item.data"
          :special="1"
          background="green"
        />
      </div>
      <div class="middle">
        <left-chart
          class="line-chart"
          v-for="item in oddDayData"
          :name="item.name"
          :key="item.name"
          :body="item.data"
        />
      </div>
      <div class="right">
        <right-table
          class=""
          v-for="(item, index) in profitRightData"
          :name="item.name"
          :key="item.name"
          :index="index"
          :dateType="item.type"
          :tableBody="item.data"
          @openDialog="openRightTableDialog"
          @changeDate="profitChangeDate(arguments)"
        ></right-table>
      </div>
    </div>

    <div class="font-22 color-fff">
      <div class="title">排名情况</div>
    </div>
    <div class="group-select flex-between">
      <div class="crumbs flex-between">
        <div :class="'item' + index" v-for="(item, index) in groupOptions" :key="index">
          <div
            class="span font-12"
            v-if="rankGroupLevel >= index"
            @click="toPrev(index)">
              {{item.name}}
          </div>
        </div>
      </div>
      <div class="flex-between">
        <date-picker
          v-model="rankOneOption.date"
          type="daterange"
          :clearable="false"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="rankTimeSelector">
        </date-picker>
        <mua-button-group class="ml-36">
          <mua-button :type="rankProfit === 1 ? 'active' : ''" @click="selectorHandle(4, 1)">毛利达成率</mua-button>
          <mua-button :type="rankProfit === 2 ? 'active' : ''" @click="selectorHandle(4, 2)">订单数达成率</mua-button>
        </mua-button-group>
      </div>
    </div>
    <div class="rank-data">
      <div class="flex-top" v-if="rankProfit === 1">
        <rank-table
          class="item-table1"
          v-for="item in rankProfitTableData"
          :key="item.name"
          :tableHead="item.head"
          :tableBody="item.data"
          :name="item.name"
          :showPM="rankGroupLevel > 0"
          @toNext="toNext"
        />
      </div>
      <div class="flex-top" v-else>
        <rank-table
          class="item-table2"
          v-for="item in rankOrderTableData"
          :key="item.name"
          :tableHead="item.head"
          :tableBody="item.data"
          :name="item.name"
          :showPM="rankGroupLevel > 0"
          @toNext="toNext"
        />
      </div>
    </div>

    <div class="rank-charts-search flex-between font-22 color-fff">
      <div></div>
      <div class="flex-between">
        <date-picker
          v-model="rankTwoOption.date"
          type="daterange"
          :clearable="false"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="rankChartTimeSelector">
        </date-picker>
      </div>
    </div>
    <div class="flex-top" v-if="rankProfit === 1">
      <div class="flex-between">
        <left-chart
          class="rank-chart-item"
          v-for="item in rankProfitChartData"
          :name="item.name"
          :key="item.name"
          :body="item.data"
          :background="item.bg"
        />
      </div>
    </div>
    <div v-else>
      <div class="flex-between">
        <left-chart
          class="rank-order-chart-item"
          v-for="item in rankOrderChartData"
          :name="item.name"
          :key="item.name"
          :body="item.data"
          :background="item.bg"
        />
      </div>
    </div>

    <AmpDialog ref="rightTable">
      <right-table
        :isAmp="true"
        :name="profitRightDataDialog.name"
        :key="profitRightDataDialog.name"
        :dateType="profitRightDataDialog.type"
        :tableBody="profitRightDataDialog.data"
        @changeDate="profitChangeDate(arguments)"
      />
    </AmpDialog>
  </main>
</template>

<script>
import {DatePicker, Select, Option} from 'element-ui' 
import LeftChart from './components/leftChart'
import RankTable from './components/rankTable'
import RightTable from './components/rightTable'
import AmpDialog from './components/ampDialog'
import { targetProfitChart, targetProfitTable, singleProfitTable, rankPorfitTable, rankOrderTable, rankPorfitChart, rankOrderChart } from './api/dealApi'

// let apitest = false
// const api = process.env.NODE_ENV === 'development' || apitest ? 'http://swoftdatat.4008737773.com/' : 'http://swoftdata.4008737773.com/'
const api = 'http://swoftdata.4008737773.com/'

export default {
  name: 'GrailMode',
  components: {
    LeftChart,
    RankTable,
    RightTable,
    AmpDialog,
    DatePicker,
    ElSelect: Select,
    ElOption: Option,
  },
  data() {
    return {
      dataParams: { // [数据情况]筛选参数
        date: '',
        period: 1, // 周期
        pattern: 1, // 类型
        value: []
      },
      dataOptions: { // [数据情况]具体参数
        city: [],
        group: [],
        operate: [],
        shop: []
      },
      profitData: [ // 数据情况毛利相关图表
        {
          name: 'profitGrailFlow',
          data: []
        },
        {
          name: 'profitMeituanFlow',
          data: []
        },
        {
          name: 'profitEleFlow',
          data: []
        }
      ],
      oddDayData: [ // 数据情况毛利相关图表
        {
          name: 'oddGrailFlow',
          data: []
        },
        {
          name: 'oddMeituanFlow',
          data: []
        },
        {
          name: 'oddEleFlow',
          data: []
        }
      ],
      profitRightRootData: [],
      profitRightDataDialog: {},
      profitRightData: [ // 数据情况毛利表格
        {
          name: 'profitGrail',
          type: 1, // 周期
          data: {}
        },
        {
          name: 'profitMt',
          type: 1,
          data: {}
        },
        {
          name: 'profitEle',
          type: 1,
          data: {}
        }
      ],
      groupOptions: [ // 总组上下级切换
        {
          name: '总组',
          groupid: 0,
        }, {
          name: '团队',
          groupid: 0,
        }, {
          name: '个人',
          groupid: 0,
        }
      ],
      rankGroupLevel: 0, // 排名层级(总组、团队、个人)下标
      rankProfit: 1,
      rankOneOption: { // 排名情况筛选项第一部分
        date: []
      },
      rankTwoOption: { // 排名情况筛选项第二部分
        date: [],
        person: 0
      },
      rankProfitTableData: [ // 毛利表格排行
        {
          name: 'profitYield',
          head: [{
            title: '分组',
            dataIndex: 'name',
            key: 'name'
          }, {
            title: '整体',
            dataIndex: 'total',
            key: 'total'
          }, {
            title: '美团',
            dataIndex: 'mt',
            key: 'mt'
          }, {
            title: '饿了么',
            dataIndex: 'ele',
            key: 'ele'
          }],
          data: []
        },
        {
          name: 'profitRank',
            head: [{
              title: '分组',
              dataIndex: 'name',
              key: 'name'
            }, {
              title: '整体',
              dataIndex: 'rank',
              key: 'rank'
            }, {
              title: '美团',
              dataIndex: 'mtRank',
              key: 'mtRank'
            }, {
              title: '饿了么',
              dataIndex: 'eleRank',
              key: 'eleRank'
            }, {
            title: '差距',
            dataIndex: 'gap',
            key: 'gap'
          }],
          data: []
        },
        {
          name: 'profitAdRate',
          head: [{
            title: '分组',
            dataIndex: 'name',
            key: 'name'
          }, {
            title: '美团广告/销售额',
            dataIndex: 'mtAd_orderPrice',
            key: 'mtAd_orderPrice'
          }, {
            title: '饿了么广告/销售额',
            dataIndex: 'eleAd_orderPrice',
            key: 'eleAd_orderPrice'
          }, {
            title: '减去1%广告后达标率',
            dataIndex: 'ad_rate',
            key: 'ad_rate'
          }, {
            title: '减去1%广告后达标率排名',
            dataIndex: 'ad_rank',
            key: 'ad_rank'
          }, {
            title: '广告费占比',
            dataIndex: 'ad_fee',
            key: 'ad_fee'
          }],
          data: []
        }
      ],
      rankOrderTableData: [ // 毛利图表排行
        {
          name: 'orderYield',
          head: [{
            title: '分组',
            dataIndex: 'name',
            key: 'name'
          }, {
            title: '整体',
            dataIndex: 'total',
            key: 'total'
          }, {
            title: '美团',
            dataIndex: 'mt',
            key: 'mt'
          }, {
            title: '饿了么',
            dataIndex: 'ele',
            key: 'ele'
          }],
          data: []
        },
        {
          name: 'orderRank',
            head: [{
              title: '分组',
              dataIndex: 'name',
              key: 'name'
            }, {
              title: '整体',
              dataIndex: 'rank',
              key: 'rank'
            }, {
              title: '美团',
              dataIndex: 'mtRank',
              key: 'mtRank'
            }, {
              title: '饿了么',
              dataIndex: 'eleRank',
              key: 'eleRank'
            }, {
            title: '差距',
            dataIndex: 'gap',
            key: 'gap'
          }],
          data: []
        }
      ],
      rankProfitChartData: [ // 数据情况毛利相关图表
        {
          name: 'profitRankChart',
          bg: 'green',
          data: []
        },
        {
          name: 'profitAdRateChart',
          bg: 'blue',
          data: []
        }
      ],
      rankOrderChartData: [ // 数据情况订单相关图表
        {
          name: 'orderRankChart',
          bg: 'green',
          data: []
        }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init () {
      const week = [
        global.moment().subtract(7, 'days').format('YYYY-MM-DD'),
        global.moment().format('YYYY-MM-DD')
      ]
      this.rankOneOption.date = week
      this.rankTwoOption.date = week
      this.dataParams.date = global.moment().format('YYYY-MM-DD')
      this.selectOptions()
      this.profitChartData()
      this.profitTableData()
      this.rankTableData()
      this.rankChartData()
    },
    // 基础筛选项
    async selectOptions() {
      const {city, group, operate, shop} = await this.$https.get(api + 'data/getBaseData')
      try {
        this.dataOptions.city = city
        this.dataOptions.group = group
        this.dataOptions.operate = operate
        this.dataOptions.shop = shop
      } catch (err) {
        console.error(err)
      }
    },
    // 毛利图标
    async profitChartData() {
      const {date, pattern, period, value} = this.dataParams
      const params = {
        group_type: pattern,
        group_id: value.join(','),
        date_type: period,
        date: date
      }
      this.$muaLoadingShow()
      try {
        const data = await this.$https.get(api + 'data/getLineData', {params})
        const res = targetProfitChart(data)
        this.profitData.forEach(item => {
          item.data = res[item.name]
        })
        this.oddDayData.forEach(item => {
          item.data = res[item.name]
        })
      } catch (err) {
        console.error(err)
      }
      this.$muaLoadingHide()
    },
    // 毛利总表格
    async profitTableData() {
      const {date, pattern, period, value} = this.dataParams
      let pendDate = [{
        platType: -1, // 订单类型
        name: 'totalData',
        dateList: [], // 环比日期
        ringData: [], // 环比数据
        rootData: {} // 基础数据
      }, {
        platType: 9,
        name: 'mtData',
        dateList: [],
        ringData: [],
        rootData: {}
      }, {
        platType: 8,
        name: 'eleData',
        dateList: [],
        ringData: [],
        rootData: {}
      }]
      if (period === 1) { // 环比昨日
        pendDate.forEach(item => {
          item.dateList = [
            global.moment(date).subtract(1, 'days').format('YYYY-MM-DD'),
            global.moment(date).subtract(7, 'days').format('YYYY-MM-DD'),
            global.moment(date).subtract(28, 'days').format('YYYY-MM-DD')
          ]
        })
      } else if (period === 2) { // 环比上周
        pendDate.forEach(item => {
          item.dateList = [
            global.moment(date).subtract(1, 'weeks').format('YYYY-MM-DD'),
            global.moment(date).subtract(4, 'weeks').format('YYYY-MM-DD')
          ]
        })
      } else { // 环比上月
        pendDate.forEach(item => {
          item.dateList = [
            global.moment(date).subtract(1, 'months').format('YYYY-MM-DD')
          ]
        })
      }

      this.$muaLoadingShow()
      try {
        // 基础数据
        const baseData = await this.$https.get(api + 'data/getTableBaseData', {
          params: {
            group_type: pattern,
            group_id: value.join(','),
            date_type: period,
            date: date
          }
        })
        const baseTableData = targetProfitTable(baseData)
        // 环比数据
        for(let item of pendDate) {
          let rootData = baseData[item.name].data
          let dataArr = []
          for(let dateItem of item.dateList) {
            const singleData = await this.$https.get(api + 'data/getTableData', {
              params: {
                group_type: pattern,
                group_id: value.join(','),
                date_type: period,
                date: dateItem,
                plat_type: item.platType
              }
            })
            let baseData3 = singleProfitTable(dateItem, item.platType, rootData, singleData)
            dataArr.push(baseData3)
          }
          item.ringData = dataArr
          item.rootData = rootData
        }
        this.profitRightRootData = []
        this.profitRightData.forEach((item, index) => {
          item.type = this.dataParams.period * 1
          item.data = {
            ...baseTableData[item.name],
            data3: pendDate[index].ringData
          }
          this.profitRightRootData[index] = pendDate[index].rootData
        })
      } catch (err) {
        console.error(err)
      }
      this.$muaLoadingHide()

    },
    async getSingleData () {

    },
    // 毛利日期
    async profitChangeDate(argusdata) {
      // argusdata[profitRightRootData 标识, profitRightData 标识, 更新时间, 平台]
      const [index2, time] = [argusdata[1], argusdata[2]]
      const {pattern, period, value} = this.dataParams
      let platTypeOptions = [{
          index: 0,
          value: -1
        }, {
          index: 1,
          value: 9
        }, {
          index: 2,
          value: 8
        }
      ]
      this.$muaLoadingShow()
      for(let item of platTypeOptions) {
        const singleData = await this.$https.get(api + 'data/getTableData', {
          params: {
            group_type: pattern,
            group_id: value.join(','),
            date_type: period,
            date: time,
            plat_type: item.value
          }
        })
        let baseData = singleProfitTable(time, item.value, this.profitRightRootData[item.index], singleData)
        this.profitRightData[item.index].data.data3.splice(index2, 1, baseData)
      }
      this.$muaLoadingHide()
    },
    // 排名表格
    async rankTableData() {
      const {date} = this.rankOneOption
      const level = this.rankGroupLevel
      const groupid = this.groupOptions[level].groupid
      const params = {
        rate_type: this.rankProfit,
        group_id: groupid,
        start_time: date[0],
        end_time: date[1]
      }
      this.$muaLoadingShow()
      try {
        const data = await this.$https.get(api + 'data/getRankTableData', {params})
        if (this.rankProfit === 2) {
          const res = rankOrderTable(data)
          this.rankOrderTableData.forEach(item => {
            item.data = res[item.name]
          })
        } else {
          const res = rankPorfitTable(data)
          this.rankProfitTableData.forEach(item => {
            item.data = res[item.name]
          })
        }
      } catch (err) {
        console.error(err)
      }
      this.$muaLoadingHide()
    },
    // 排名图表
    async rankChartData() {
      const {date} = this.rankTwoOption
      const level = this.rankGroupLevel
      const groupid = this.groupOptions[level].groupid
      const params = {
        rate_type: this.rankProfit,
        group_id: groupid,
        start_time: date[0],
        end_time: date[1]
      }
      this.$muaLoadingShow()
      try {
        const data = await this.$https.get(api + 'data/getRankLineData', {params})
        if (this.rankProfit === 2) {
          const res = rankOrderChart(data)
          this.rankOrderChartData.forEach(item => {
            item.data = res[item.name]
          })
        } else {
          const res = rankPorfitChart(data)
          this.rankProfitChartData.forEach(item => {
            item.data = res[item.name]
          })
        }
      } catch (err) {
        console.error(err)
      }
      this.$muaLoadingHide()
    },
    // 筛选项触发
    selectorHandle(type, index) {
      if (type === 0) {
        this.dataParams.value = []
        this.dataParams.period = index
        this.profitChartData()
        this.profitTableData()
      }
      if (type === 2) {
        this.profitChartData()
        this.profitTableData()
      }
      if (type === 1) {
        this.dataParams.value = []
        this.dataParams.pattern = index
        this.profitChartData()
        this.profitTableData()
      }
      if (type === 4) { // 切换毛利和订单排名的排序
        this.rankProfit = index
        this.rankGroupLevel = 0
        this.rankTableData()
        this.rankChartData()
      }
    },
    // 排名面包屑上一级
    toPrev(index) { // 0 总组 1 团队 2 个人
      if (index === 1) {
        this.rankGroupLevel = 1
        this.rankTableData()
        this.rankChartData()
      }
      if (index === 0) {
        this.rankGroupLevel = 0
        this.rankTableData()
        this.rankChartData()
      }
    },
    // 排名面包屑下一级
    toNext(e) {
      if (e && e.id && e.type * 1 === 1) {
        let level = this.rankGroupLevel * 1
        if (level === 0 || level === 1) {
          if (level === 0) this.groupOptions[1].groupid = e.id
          if (level === 1) this.groupOptions[2].groupid = e.id
          this.rankGroupLevel += 1
          this.rankTableData()
          this.rankChartData()
        }
      }
    },
    // 表格排名时间选择器
    rankTimeSelector() {
      this.rankTwoOption.date = this.rankOneOption.date
      this.rankTableData()
      this.rankChartData()
    },
    // 图表排名时间选择器
    rankChartTimeSelector() {
      this.rankChartData()
    },
    // 打开右侧表格弹框
    openRightTableDialog (...args) {
      const [index, bool] = [...args]
      if (!bool) {
        this.profitRightDataDialog = this.profitRightData[index]
        this.$refs.rightTable.open()
      }
    }
  }
}
</script>

<style lang="less">
.main {
  .el-input, .el-tag.el-tag--info {
    color: #ffffff;
    font-size: 10px;
  }

  .el-input__icon {
    line-height: 14px;
  }

  .el-input__inner {
    height: 22px !important;
    color: #ffffff;
    border-radius:3px;
    border:1px solid rgba(46,219,255,1);
    background-color: transparent;
  }

  .el-date-editor .el-range-input {
    font-size: 10px;
    color: #ffffff;
    background-color: transparent;
  }

  .el-date-editor .el-range-separator {
    color: #ffffff;
    line-height: 16px;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 150px;
  }

  .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 230px;
  }

  .el-select .el-tag {
    background-color: transparent;
    white-space: nowrap;
    margin: 0;
  }

  .el-select .el-input {
    width: 202px;
  }
  .el-select .el-select__tags {
    height: 28px;
  }
}
</style>

<style lang="less" scoped>
.main {
  padding-right: 6px;
  &::-webkit-scrollbar{
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2EDBFF;
    border-radius: 6px;
  }
  .ml-36 {
    margin-left: 2.25rem;
  }
  display: flex;
  flex-direction: column;
  height: calc(100% - 65px - 4.625rem);
  padding-bottom: 2rem;
  overflow-y: auto;
  box-sizing: border-box;
  .title {
    height: 24px;
    line-height: 24px;
    font-weight: 500;
    padding-left: 0.575rem;
    position: relative;
  }
  .title::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient( 180deg, rgba( 36,131,255,1) 0%, rgba( 1,209,138,1) 100%);
  }
  .profit-data {
    display: flex;
    justify-content: space-between;
    align-items: top;
    .left, .middle, .right {
      width: 30%;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }
    .right {
      width: 38%;
      > div {
        margin-bottom: 1.5rem;
      }
    }
  }
  .rank-data {
    margin-bottom: 1.5rem;
    .flex-top {
      display: flex;
      justify-content: space-between;
      align-items: top;
    }
    .item-table1:nth-child(1) {
      width: 23%;
    }
    .item-table1:nth-child(2) {
      width: 26%;
    }
    .item-table1:nth-child(3) {
      width: 48%;
    }
    .item-table2:nth-child(1) {
      width: 48.5%;
    }
    .item-table2:nth-child(2) {
      width: 48.5%;
    }
  }
  .rank-chart-item {
    width: 49%;
  }
  .rank-order-chart-item {
    width: 100%;
  }
  .rank-charts-search {
    margin-bottom: 1rem;
  }
  .group-select {
    margin: .5rem 0 1rem 0;
  }
  .crumbs {
    .item0, .item1, .item2 {
      color: #FFF;
      cursor: pointer;
    }
    .item1 .span, .item2 .span {
      color: #2EDBFF;
      margin-left: .8125rem;
      position: relative;
    }
    .item1 .span::before, .item2 .span::before {
      position: absolute;
      content: '>';
      color: #FFF;
      font-size: .75rem;
      top: -1px;
      left: -10px;
    }
  }
}
</style>