<template>
  <div class="amp-dialog" v-if="visible">
    <div class="dark"></div>
    <div class="amp-body">
      <img @click="close" class="close" src="@/assets/close.png" alt="close">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scroped>
.dark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  z-index: 199;
}
.amp-body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 200;
  background-image: linear-gradient(180deg, #12183D 0%, #05092D 100%);
  width: 70%;
  .close {
    margin: 2rem 3rem 0 0;
    width: 1.5rem;
    height: 1.5rem;
    float: right;
    cursor: pointer;
  }
}
</style>